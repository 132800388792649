.iframe-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  backdrop-filter: blur(2px);
  padding: 20px;
}

.iframe-modal-container {
  position: relative;
  background-color: transparent;
  width: 100%;
  max-width: 650px;
  height: 90vh;
  max-height: 900px;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  animation: modalFadeIn 0.3s ease-out;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.iframe-modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: gray;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
  font-size: 18px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.iframe-modal-close-btn:hover {
  background-color: #ff6b6b;
}

.iframe-modal-content {
  width: 100%;
  height: 100%;
  border: none;
}

/* Media Queries for better mobile experience */
@media screen and (max-width: 480px) {
  .iframe-modal-overlay {
    padding: 10px;
  }
  
  .iframe-modal-container {
    height: 95vh;
  }
  
  .iframe-modal-close-btn {
    top: 5px;
    right: 5px;
    width: 25px;
    height: 25px;
    font-size: 16px;
  }
} 