// Brand List
.brand-list{
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 0;
	li{
		display: inline-block;
		width: 33.33%;
		text-align: center;
		padding: 20px;
		border-bottom: 1px solid rgba(0,0,0,0.1);
		border-right: 1px solid rgba(0,0,0,0.1);
		img{
			opacity:0.4;
			transition:all 0.5s;
		}
		&:hover{
			img{
				opacity:1;
			}
		}
		&:nth-child(3n+0){
			border-right: 0;
		}
		&:nth-last-child(1),
		&:nth-last-child(2),
		&:nth-last-child(3){
			border-bottom: 0;
		}
	}
	@include respond('phone'){
		li{
			width: 50%;
			&:nth-child(3n+0){
				border-right: 1px solid rgba(0,0,0,0.1);
			}
			&:nth-last-child(3){
				border-bottom: 1px solid rgba(0,0,0,0.1);
			}
			&:nth-child(2n+0){
				border-right: 0;
			}
		}
	}
}

// About Wraper
.about-wraper1{
	position:relative;
	.about-media{
		position: relative;
		height: 100%;
		padding: 80px 80px 100px 150px;
	}
	.about-img1{
		position: absolute;
		top: 0;
		left: 0;
		border: 10px solid #fff;
		z-index: 1;
		width: 250px;
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
		-webkit-animation: move 9s infinite  alternate;
		animation:move  9s infinite  alternate;
	}
	.about-img2{
		float: right;
		position: relative;
		width: 100%;
		img{
			width: 100%;
		}
		&:before{
		    content: "";
			position: absolute;
			width: 72px;
			height: 72px;
			background-color: var(--primary);
			z-index: 2;
			bottom: -30px;
			left: -30px;
			-webkit-animation: zoom-in-zoom-out 5s infinite  alternate;
			animation:zoom-in-zoom-out 5s infinite  alternate;
			
		}
	}
	.experience-bx{
	    position: absolute;
		right: 30px;
		bottom: 0;
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
		background-color: #fff;
		padding: 35px 30px;
		overflow: hidden;
		border-bottom: 5px solid var(--primary);
		-webkit-animation: move2 7s infinite  alternate;
		animation:move2  7s infinite  alternate;
		
		.exp-text{
			font-size: 36px;
			color: var(--primary);
			line-height: 1;
			text-transform: uppercase;
			display: flex;
			align-items: flex-end;
			margin-bottom: 15px;
			.counter{
				font-size: 110px;
				text-transform: uppercase;
				line-height: 85px;
				display: inline-block;
				color: #000;
			}
		}
		.exp-year{
			font-size: 16px;
			text-transform: uppercase;
			margin: 0;
		}
	}
	@include respond('tab-land'){
		.about-img1{
			width: 200px;
		}
		.about-media{
			padding: 50px 70px 120px 50px;
		}
	}
	@include respond('tab-port'){
		.about-img1{
			width: 250px;
		}
		.about-media{
			padding: 70px 120px 70px 140px;
		}
	}
	@include respond('phone-land'){
		.about-media{
			padding: 60px;
		}
		.about-img1 {
			width: 200px;
		}
	}
	@include respond('phone'){
		.about-img1 {
			width: 120px;
			border-width: 5px;
			left: 20px;
		}
		.experience-bx{
			padding: 20px;
			.exp-text{
				.counter {
					font-size: 80px;
					line-height: 60px;
				}
			}
		}
	}
}
//move animation
@-webkit-keyframes move {
	0% {
		-webkit-transform: translate(0);
		transform: translate(0);
	}
	25% {
		-webkit-transform: translate(10px, 10px);
		transform: translate(10px, 10px);
	}
	50% {
		-webkit-transform: translate(5px, 5px);
		transform: translate(5px, 5px);
	}
	75% {
		-webkit-transform: translate(10px, -5px);
		transform: translate(10px, -5px);
	}
	100% {
		-webkit-transform: translate(0);
		transform: translate(0);
	}
}
@-webkit-keyframes move2 {
	0% {
		-webkit-transform: translate(0);
		transform: translate(0);
	}
	25% {
		-webkit-transform: translate(-10px, -10px);
		transform: translate(-10px, -10px);
	}
	50% {
		-webkit-transform: translate(-5px, -5px);
		transform: translate(-5px, -5px);
	}
	75% {
		-webkit-transform: translate(-10px, 5px);
		transform: translate(-10px, 5px);
	}
	100% {
		-webkit-transform: translate(0);
		transform: translate(0);
	}
}
//animation jumb-bounch
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}
 
// List Check Squer
.list-check-squer{
	li{
		display: block;
		color: #000;
		font-size: 20px;
		font-family: 'Roboto Condensed';
		font-weight: 500;
		position: relative;
		padding: 8px 15px 7px 50px;
		margin-bottom: 10px;
		line-height: 20px;
		&:before{
			content: "\f00c";
			font-family: 'Line Awesome Free';
			font-weight: 900;
			background-color: var(--primary);
			width: 35px;
			height: 35px;
			display: block;
			text-align: center;
			line-height: 35px;
			color: #fff;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
		@include respond('phone'){
			font-size: 16px;
			padding: 8px 15px 7px 46px;
			line-height: 16px;
		}
	}
}
 
// heading-ext
.heading-ext{
	display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 40px;
	.right{
		min-width: 200px;
		text-align: right;;
	}
	@include respond('phone-land'){
		padding: 20px;
	}
	@include respond('phone'){
		display: block;
		.right {
			text-align: left;
			margin-top: 15px;
		}
	}
}

// Team Wraper1
.team-wraper1{
	position: relative;
    width: 100%;
    height: 100%;
    display: table;
	.media-large{
		float: right;
		margin: 30px;

		img{
			border-radius: 10px;
		}
	}
	.team-media{
		width: 80px;
		height: 80px;
		border: 3px solid;
		border-color: #fff;
		img{
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}
	.team-info{
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
		padding: 30px;
		background-color: #fff;
		width: 280px;
		position: absolute;
		right: 100%;
		top: 50%;
		transform: translateY(-50%);
		margin-right: 20px;
		opacity: 0;
		visibility: hidden;
		@include durationMedium;
		z-index: 1;
			
		&:after{
			content: "";
			border-top: 15px solid #fff;
			border-right: 15px solid transparent;
			position: absolute;
			right: -15px;
			top: 35%;
			transform: translateY(-50%);
		}
		.title{
			margin-bottom: 5px;
		}
		span{
			display: block;
			margin-bottom: 20px;
		}
	}
	.team-bx{
		position: absolute;
		&:hover{
			.team-info{
				opacity: 1;
				visibility: visible;
			}
		}
	}
	.member1{
		left: 250px;
		bottom: 0;
	}
	.member2{
		right: 0;
		bottom: 0;
		.team-media{
			transform: scale(1.5);
		}
		.team-info {
			margin-right: 35px;
		}
	}
	.member3{
		right: 50px;
		top: 50px;
	}
	.member4{
		top: 0;
		left: 300px;
		.team-media{
			transform: scale(1.5);
		}
		.team-info {
			margin-right: 35px;
		}
	}
	.member5{
		top: 100px;
		left: 100px;
		.team-media{
			transform: scale(1.5);
		}
		.team-info {
			margin-right: 35px;
		}
	}
	@include respond('tab-port'){
		.member1 {
			left: 180px;
			.team-info {
				margin-right: 0;
				margin-left: 15px;
				right: 0;
				left: 100%;
				&:after {
					right: auto;
					top: 35%;
					transform: translateY(-50%) rotate(90deg);
					left: -15px;
				}
			}
		}
		.member5 {
			left: 0;
			.team-info {
				margin-right: 0;
				margin-left: 35px;
				right: 0;
				left: 100%;
				&:after {
					right: auto;
					top: 35%;
					transform: translateY(-50%) rotate(90deg);
					left: -15px;
				}
			}
		}
	}
}

// Blog Carousel
.blog-carousel{
	.owl-stage-outer{
		overflow:unset;
		.owl-item{
			opacity:0.3;
			@include durationMedium;
			
			&.active{
				opacity:1;
			}
		}
	}
	.blog-card{
		margin-bottom:0;
	}
}
.blog-custom{
	display: flex;
	flex-direction: row;
	overflow: auto;
	gap:30px;

}
.post-content{
	min-height: 300px;
	min-width: 300px;
	.content-text{
		white-space:wrap;
		overflow: hidden !important;
		text-overflow: ellipsis;
	
	}
	}
// Blog Wraper
.blog-wraper1,
.service-wraper1{
	position: relative;	
	&:after{
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		height: 43%;
		width: 100%;
		background-color: $light;
	}
	@include respond('phone-land'){
		&:after{
			content: none;
		}
	}
}

// Blog Card
.blog-card{
	position: relative;
	margin-bottom:30px;
	.post-media{
		img{
			width: 100%;
		}
	}
	&.blog-list{
		display: flex;
		.post-media{
			width: 200px;
			min-width: 200px;
			img{
				height: 100%;
				object-fit: cover;
			}
		}
		&.list-large{
			.post-media{
				width: 300px;
				min-width: 300px;
			}
		}
		@include respond('tab-land'){
			&.list-large{
				.post-media{
					width: 250px;
					min-width: 250px;
				}
			}
		}
		@include respond('phone-land'){
			&.list-large{
				display: block;
				.post-media{
					width: 100%;
					min-width: 100%;
				}
			}
		}
		@include respond('phone'){
			display: block;
			.post-media{
				width: 100%;
				min-width: 100%;
			}
		}
	}
	&.blog-overlay{
		.post-info{
			position: absolute;
			bottom: 0;
			width: 100%;
			.post-meta {
				li, a, i{
					color: #fff;
				}
			}
			.post-title{
				a{
					color: #fff;
				}
			}
		}
	}
	
	&.style-1{
		.post-title{
			margin-bottom: 12px;
		}
		.post-info{
			padding:30px;
			.post-meta{
				border-top: 1px solid #eee;
				margin-bottom: -5px;
				padding-top: 15px;
				margin-top: 20px;
			}
		}
		
		&.blog-overlay{
			.post-info{
				z-index: 2;
				.post-meta {
					margin-bottom: 0;
					border-top: 1px solid rgba(255,255,255,0.2);
					padding-top: 15px;
					margin-bottom: 0;
				}
			}
			.post-media{
				position:relative;
				z-index:1;
				img{
					min-height:300px;
					object-fit:cover;
				}
				&:after{
					content:"";
					position:absolute;
					left:0;
					top:0;
					background: -moz-linear-gradient(top, rgba(23,26,30,0) 0%, rgba(23,26,30,0.8) 100%); /* FF3.6-15 */
					background: -webkit-linear-gradient(top, rgba(23,26,30,0) 0%,rgba(23,26,30,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(to bottom, rgba(23,26,30,0) 0%,rgba(23,26,30,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					z-index:1;
					width:100%;
					height:100%;
					
				}
			}
		}
		&.blog-list{
			.post-info{
				padding: 35px 30px; 
			}
		}
		@include respond('tab-port'){
			&.blog-list{
				.post-info{
					padding:25px 20px;
				}
			}
			.post-info{
				padding:30px 25px;
			}
		}
		@include respond('phone'){
			&.blog-list{
				.post-info{
					padding:30px 25px;
				}
			}
		}
	}
}

// Post Meta
.post-meta{
	padding:0;
	margin:0;
	list-style:none;
	li{
		display:inline-block;
		position: relative;
		font-size: 14px;
		&:after{
			content: "-";
			margin-left: 10px;
			margin-right: 10px;
			display: inline-block;
			
		}
		&:last-child:after{
			content:none;
		}
		a{
			color:$body-color;
		}
	}
	.author{
		img{
			border-radius: 30px;
			height: 30px;
			width: 30px;
			display: inline-block;
			margin-right: 10px;
		}
		a{
			color: $primary;
		}
	}
}
