.service-page {
    .feature-container {
        transition: all 0.3s ease;
        border: 1px solid #eee;

        &:hover {
            transform: translateY(-5px);
            box-shadow: 0 5px 15px rgba(0,0,0,0.1);

            .icon-lg {
                &:before {
                    animation: pulse 2s infinite;
                }
            }
        }

        .icon-lg {
            width: 80px;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: rgba(16, 216, 118, 0.1);
            margin: 0 auto;
            position: relative;
            
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background: rgba(16, 216, 118, 0.1);
                transition: all 0.3s ease;
            }
            
            svg {
                width: 40px;
                height: 40px;
                fill: #10d876;
                position: relative;
                z-index: 1;
            }
        }

        h4 {
            margin: 15px 0 10px;
            font-weight: 600;
            color: #1f2471;
        }

        p {
            color: #666;
            margin-bottom: 0;
            font-size: 14px;
            line-height: 1.6;
        }
    }

    .info-box {
        h3 {
            margin-bottom: 20px;
            color: #1f2471;
        }

        .list-check {
            li {
                margin-bottom: 12px;
                padding-left: 30px;
                position: relative;

                &:before {
                    content: "\f00c";
                    font-family: "FontAwesome";
                    position: absolute;
                    left: 0;
                    top: 0;
                    color: #10d876;
                }
            }
        }
    }

    .section-head {
        .title {
            color: #1f2471;
            margin-bottom: 10px;
        }

        .sub-title {
            color: #10d876;
            font-size: 18px;
            font-weight: 500;
        }
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.2);
        opacity: 0.5;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}