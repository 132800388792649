@import "./scss/main";

.App{
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.threedots-loader {
  width: 60px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side,#10d876 90%,#0000);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
}
.sliderTitle{
  font-weight: 500;
}
.carTypeInput{
  border: 1px solid #81acd2;
}
.cookie-notice {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: rgba(31, 36, 113, 0.95);
  color: #fff;
  padding: 15px 20px;
  border-radius: 8px;
  z-index: 9999;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  animation: slideIn 0.5s ease-out;
  
  p {
    margin: 0 0 12px 0;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 8px;
    
    i.fa-cookie-bite {
      color: #10d876;
      font-size: 16px;
    }
  }
  
  .cookie-buttons {
    display: flex;
    gap: 10px;
    
    button {
      flex: 1;
      padding: 8px;
      border-radius: 4px;
      border: none;
      font-size: 13px;
      cursor: pointer;
      transition: all 0.3s ease;
      
      &.accept-btn {
        background: #10d876;
        color: #fff;
        
        &:hover {
          background: darken(#10d876, 10%);
        }
      }
      
      &.decline-btn {
        background: rgba(255, 255, 255, 0.1);
        color: #fff;
        
        &:hover {
          background: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@media (max-width: 767px) {
  .cookie-notice {
    bottom: 10px;
    right: 10px;
    left: 10px;
    max-width: none;
  }
}